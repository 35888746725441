import React, { Fragment } from 'react';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { SignedIn, SignedOut } from '@clerk/nextjs';
import { Popover, Transition } from '@headlessui/react';
import {
  BookOpenIcon,
  MenuIcon,
  XIcon,
  ArrowRightIcon
} from '@heroicons/react/outline';
import classNames from 'classnames';
import Company from './company';
import Developers from './developers';
import Product from './product';
import { useCloseMenuOnNavigate } from './use-close-menu-on-navigate';
import { openPopupWidget } from 'react-calendly';
import { useToggleIntercom } from '@hooks';
import { Box, Button } from '@chakra-ui/react';
import { PURPLE_BG_PAGES, URLS, SECTION_IDS } from '@lib';
import { HEADER_HEIGHT } from '@components/layout/header';
import { analytics, rudderTrack } from 'pages';

const { home } = URLS;

const { header } = SECTION_IDS;
const LOGO_AND_CTAS_WIDTH = '260px';

function MobileMenuButton({ isOpen, closeMenu, textColor }) {
  useCloseMenuOnNavigate({ closeMenu });
  useToggleIntercom({ shouldBeVisible: !isOpen });

  return (
    <div className="-my-2 -mr-2 lg:hidden">
      <Popover.Button
        className={classNames(
          'inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500',
          textColor
        )}
      >
        <span className="sr-only">Open menu</span>
        <MenuIcon className="w-6 h-6" aria-hidden="true" />
      </Popover.Button>
    </div>
  );
}

export function MegaMenu() {
  const [hasScrolled, setHasScrolled] = React.useState(false);

  const { pathname } = useRouter();

  const hasPurpleBg = PURPLE_BG_PAGES.includes(pathname);
  const logo =
    hasPurpleBg || pathname.includes('components')
      ? '/images/clerk-logo-dark.svg'
      : '/images/clerk-logo.svg';
  const bg =
    hasPurpleBg || (pathname.includes('/components') && hasScrolled)
      ? '#111449'
      : 'none';
  const textColor =
    hasPurpleBg || pathname.includes('components')
      ? 'text-white hover:text-clerk-gray-100'
      : 'text-clerk-gray-800 hover:text-clerk-gray-900';
  const highlightTextColor = hasPurpleBg ? 'text-[#8370FF]' : 'text-[#6C47FF]';

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const onScroll = () => {
        const scrollY = window.scrollY;

        if (scrollY > 50 && !hasScrolled) {
          setHasScrolled(true);
        } else if (scrollY <= 50 && hasScrolled) {
          setHasScrolled(false);
        }
      };

      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [hasScrolled]);

  return (
    <Box
      as="header"
      id={header}
      pos="fixed"
      bg={
        hasScrolled && pathname.includes('components')
          ? bg
          : hasScrolled && !hasPurpleBg
          ? 'white'
          : hasScrolled && hasPurpleBg
          ? '#0C0129'
          : 'transparent'
      }
      top={0}
      w="full"
      borderBottom={
        hasScrolled && !hasPurpleBg && !pathname.includes('/components')
          ? '1px solid'
          : 'none'
      }
      borderColor="gray.200"
      shadow={hasScrolled ? 'base' : 'none'}
      transition="all 0.2s"
      zIndex="sticky"
    >
      {/* ANNOUNCEMENT */}
      {/* !!! Switch `PADDING_TOP` on `/pages/_app.tsx` depending if the banner is active or not !!! */}

      {/* <Box bg="primary.500" textColor="white" px={4} py={3} textAlign="center">
        Clerk raises $15m Series A led by Madrona.{' '}
        <Link href="/blog/series-a" target="_blank" rel="noopener">
          <Box as="span" textColor="white" fontWeight={700}>
            Read more
          </Box>
        </Link>
        !
      </Box> */}

      {/* END ANNOUNCEMENT */}

      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Box
              className="flex items-center justify-between px-4 mx-auto max-w-7xl sm:px-6 lg:space-x-10 lg:px-8"
              h={HEADER_HEIGHT}
            >
              <Box
                className="flex items-center"
                w={LOGO_AND_CTAS_WIDTH}
                ml="0 !important"
              >
                <div
                  className={!pathname.includes('/blog') ? 'mr-[60px]' : 'mr-0'}
                >
                  <Link legacyBehavior href={home}>
                    <a href={home}>
                      <span className="sr-only">Clerk</span>
                      <div className="image-wrap">
                        {pathname.includes('/blog') && hasPurpleBg ? (
                          <Image
                            src={'/images/blog-dark.svg'}
                            height={98}
                            width={160}
                            alt="Blog"
                            priority
                          />
                        ) : pathname.includes('/blog') ? (
                          <Image
                            src={'/images/blog.svg'}
                            height={98}
                            width={160}
                            alt="Blog"
                            priority
                          />
                        ) : (
                          <Image
                            src={logo}
                            height={24}
                            width={77}
                            alt="Clerk logo"
                            priority
                          />
                        )}
                      </div>
                    </a>
                  </Link>
                </div>
              </Box>

              <nav className="hidden space-x-8 lg:flex ">
                <Box
                  ml="0 !important"
                  onClick={() => {
                    analytics.track(`Marketing_Header_Menu Product Clicked`);
                    rudderTrack(`Marketing_Header_Menu Product Clicked`);
                  }}
                >
                  <Product textColor={textColor} />
                </Box>
                <Box
                  onClick={() => {
                    analytics.track(`Marketing_Header_Menu Developers Clicked`);
                    rudderTrack(`Marketing_Header_Menu Developers Clicked`);
                  }}
                >
                  <Developers textColor={textColor} />
                </Box>
                <Box
                  onClick={() => {
                    analytics.track(`Marketing_Header_Menu Company Clicked`);
                    rudderTrack(`Marketing_Header_Menu Company Clicked`);
                  }}
                >
                  <Company textColor={textColor} />
                </Box>
                <Link legacyBehavior href="/startups">
                  <a
                    className={classNames(
                      'py-4 font-medium text-m whitespace-nowrap',
                      textColor
                    )}
                  >
                    <Box
                      onClick={() => {
                        analytics.track(
                          `Marketing_Header_Menu For startups Clicked`
                        );
                        rudderTrack(
                          `Marketing_Header_Menu For startups Clicked`
                        );
                      }}
                    >
                      For startups
                    </Box>
                  </a>
                </Link>
                <Link legacyBehavior href="/pricing">
                  <a
                    className={classNames(
                      'py-4 font-medium text-md',
                      textColor
                    )}
                  >
                    <Box
                      onClick={() => {
                        analytics.track(
                          `Marketing_Header_Menu Pricing Clicked`
                        );
                        rudderTrack(`Marketing_Header_Menu Pricing Clicked`);
                      }}
                    >
                      Pricing
                    </Box>
                  </a>
                </Link>
              </nav>

              <MobileMenuButton
                isOpen={open}
                closeMenu={close}
                textColor={textColor}
              />

              <Box
                className="items-center justify-end hidden lg:flex"
                w={LOGO_AND_CTAS_WIDTH}
              >
                <SignedOut>
                  {hasScrolled ? (
                    <Button
                      variant="ghost"
                      onClick={() => {
                        analytics.track(
                          `Marketing_Header_Menu Book a demo Clicked`
                        );
                        rudderTrack(
                          `Marketing_Header_Menu Book a demo Clicked`
                        );

                        openPopupWidget({
                          url: URLS.calendlyUrl
                        });
                      }}
                      fontSize="md"
                      className={textColor}
                      textColor={textColor}
                      _hover={{}}
                    >
                      Book a demo
                    </Button>
                  ) : (
                    <Box
                      onClick={() => {
                        analytics.track(
                          `Marketing_Header_Menu Sign in Clicked`
                        );
                        rudderTrack(`Marketing_Header_Menu Sign in Clicked`);
                      }}
                    >
                      <a
                        href={
                          process.env.NEXT_PUBLIC_DASHBOARD_URL + '/sign-in'
                        }
                        className={classNames(
                          'inline-flex items-center justify-center px-5 py-1.5 text-md font-medium border-2 rounded-md whitespace-nowrap btn btn-ghost',
                          textColor
                        )}
                      >
                        Sign in
                      </a>
                    </Box>
                  )}
                  <Box
                    onClick={() => {
                      analytics.track(`Marketing_Header_Menu Sign up Clicked`);
                      rudderTrack(`Marketing_Header_Menu Sign up Clicked`);
                    }}
                  >
                    <a
                      href={process.env.NEXT_PUBLIC_DASHBOARD_URL + '/sign-up'}
                      className="inline-flex items-center justify-center px-5 py-1.5 ml-4 text-md font-medium text-clerk-blue border border-transparent rounded-md shadow-sm bg-clerk-blue hover:bg-blue-700 whitespace-nowrap btn btn-outline"
                    >
                      Sign up
                    </a>
                  </Box>
                </SignedOut>
                <SignedIn>
                  <Box
                    onClick={() => {
                      analytics.track(`Marketing_Header_Menu Docs Clicked`);
                      rudderTrack(`Marketing_Header_Menu Docs Clicked`);
                    }}
                  >
                    <Link legacyBehavior href={URLS.docs}>
                      <a
                        className={classNames(
                          'items-center justify-center px-5 py-1.5 text-md font-medium border-2 rounded-md  border-clerk-blue whitespace-nowrap hidden [1100]:inline-flex xl:hidden btn btn-ghost',
                          highlightTextColor
                        )}
                      >
                        Docs
                      </a>
                    </Link>
                  </Box>
                  <Box
                    onClick={() => {
                      analytics.track(`Marketing_Header_Menu Docs Clicked`);
                      rudderTrack(`Marketing_Header_Menu Docs Clicked`);
                    }}
                  >
                    <Link legacyBehavior href={URLS.docs}>
                      <a
                        className={classNames(
                          'items-center justify-center px-5 py-1.5 text-md font-medium border-2 rounded-md border-clerk-blue whitespace-nowrap hidden xl:inline-flex btn btn-ghost',
                          highlightTextColor
                        )}
                      >
                        Documentation
                        {BookOpenIcon({
                          className:
                            'flex-shrink-0 w-5 h-5 ml-2 mt-0.3 text-primary-500',
                          'aria-hidden': 'true'
                        })}
                      </a>
                    </Link>
                  </Box>
                  <Box
                    onClick={() => {
                      analytics.track(
                        `Marketing_Header_Menu Dashboard Clicked`
                      );
                      rudderTrack(`Marketing_Header_Menu Dashboard Clicked`);
                    }}
                  >
                    <a
                      href={process.env.NEXT_PUBLIC_DASHBOARD_URL}
                      className="inline-flex items-center justify-center px-5 py-1.5 ml-4 text-md font-medium text-clerk-blue border border-transparent rounded-md shadow-sm bg-clerk-blue hover:bg-blue-700 whitespace-nowrap btn btn-outline"
                    >
                      Dashboard
                      {ArrowRightIcon({
                        className:
                          'flex-shrink-0 w-5 h-5 ml-2 mt-0.3 text-primary-500',
                        'aria-hidden': 'true'
                      })}
                    </a>
                  </Box>
                </SignedIn>
              </Box>
            </Box>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="fixed inset-x-0 top-0 z-30 h-full transition origin-top-right transform lg:hidden"
              >
                <div className="bg-white divide-y-2 ring-1 ring-black ring-opacity-5 divide-gray-50">
                  <div className="h-screen">
                    <div className="flex items-center justify-between w-full h-20 p-4 border-b-2">
                      <div>
                        <Image
                          src="/images/clerk-logo.svg"
                          height="28"
                          width="98"
                          alt="Clerk logo"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-500 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="w-6 h-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>

                    <div className="h-full px-5 pb-6 overflow-scroll">
                      <nav className="grid grid-cols-1 pt-4 mb-40 gap-7">
                        <div
                          className="mt-4"
                          onClick={() => {
                            analytics.track(
                              `Marketing_Header_Menu Product Clicked`
                            );
                            rudderTrack(
                              `Marketing_Header_Menu Product Clicked`
                            );
                          }}
                        >
                          <Product mobile />
                        </div>
                        <div
                          onClick={() => {
                            analytics.track(
                              `Marketing_Header_Menu Developers Clicked`
                            );
                            rudderTrack(
                              `Marketing_Header_Menu Developers Clicked`
                            );
                          }}
                        >
                          <Developers />
                        </div>
                        <div
                          onClick={() => {
                            rudderTrack(
                              `Marketing_Header_Menu Company Clicked`
                            );
                            analytics.track(
                              `Marketing_Header_Menu Company Clicked`
                            );
                          }}
                        >
                          <Company />
                        </div>
                        <div
                          onClick={() => {
                            rudderTrack(
                              `Marketing_Header_Menu For startups Clicked`
                            );
                            analytics.track(
                              `Marketing_Header_Menu For startups Clicked`
                            );
                          }}
                        >
                          <Link legacyBehavior href="/startups">
                            <a className="text-base font-medium text-gray-500 hover:text-gray-900">
                              For startups
                            </a>
                          </Link>
                        </div>
                        <div
                          onClick={() => {
                            rudderTrack(
                              `Marketing_Header_Menu Pricing Clicked`
                            );
                            analytics.track(
                              `Marketing_Header_Menu Pricing Clicked`
                            );
                          }}
                        >
                          <Link legacyBehavior href="/pricing">
                            <a className="text-base font-medium text-gray-500 hover:text-gray-900">
                              Pricing
                            </a>
                          </Link>
                        </div>
                      </nav>
                    </div>

                    <div className="absolute bottom-0 justify-around w-full h-20 p-4 bg-white border-t-2">
                      <div className="flex flex-row justify-center w-full">
                        <SignedOut>
                          <div
                            onClick={() => {
                              rudderTrack(
                                `Marketing_Header_Menu Sign in Clicked`
                              );
                              analytics.track(
                                `Marketing_Header_Menu Sign in Clicked`
                              );
                            }}
                          >
                            <a
                              href={
                                process.env.NEXT_PUBLIC_DASHBOARD_URL +
                                '/sign-in'
                              }
                              className="flex-1 items-center justify-center px-5 py-1.5 text-base font-medium border-2 rounded-md text-clerk-blue border-clerk-blue whitespace-nowrap inline-flex"
                            >
                              Sign in
                            </a>
                          </div>
                          <div
                            onClick={() => {
                              rudderTrack(
                                `Marketing_Header_Menu Sign up Clicked`
                              );
                              analytics.track(
                                `Marketing_Header_Menu Sign up Clicked`
                              );
                            }}
                          >
                            <a
                              href={
                                process.env.NEXT_PUBLIC_DASHBOARD_URL +
                                '/sign-up'
                              }
                              className="inline-flex items-center justify-center flex-1 px-4 py-2 ml-4 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-clerk-blue hover:bg-blue-700"
                            >
                              Sign up
                            </a>
                          </div>
                        </SignedOut>
                        <SignedIn>
                          <Link legacyBehavior href={URLS.docs}>
                            <div
                              onClick={() => {
                                rudderTrack(
                                  `Marketing_Header_Menu Documentation Clicked`
                                );
                                analytics.track(
                                  `Marketing_Header_Menu Documentation Clicked`
                                );
                              }}
                            >
                              <a className="flex-1 items-center justify-center px-5 py-1.5 text-base font-medium border-2 rounded-md text-clerk-blue border-clerk-blue whitespace-nowrap inline-flex">
                                Documentation
                              </a>
                            </div>
                          </Link>
                          <div
                            onClick={() => {
                              rudderTrack(
                                `Marketing_Header_Menu Dashboard Clicked`
                              );
                              analytics.track(
                                `Marketing_Header_Menu Dashboard Clicked`
                              );
                            }}
                          >
                            <a
                              href={process.env.NEXT_PUBLIC_DASHBOARD_URL}
                              className="inline-flex items-center justify-center flex-1 px-4 py-2 ml-4 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-clerk-blue hover:bg-blue-700"
                            >
                              Dashboard
                              {ArrowRightIcon({
                                className:
                                  'flex-shrink-0 w-5 h-5 ml-2 mt-0.3 text-primary-500',
                                'aria-hidden': 'true'
                              })}
                            </a>
                          </div>
                        </SignedIn>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </Box>
  );
}

export default MegaMenu;
