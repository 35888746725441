import Head from 'next/head';
import { useRouter } from 'next/router';
import url from 'url';
import { saneImageSrc } from '../../lib/sanity';

export function SEO({ seo }) {
  const currentPath = useRouter().asPath;

  if (!seo) {
    console.warn(`***** No SEO for ${currentPath} *****`);
    return null;
  }

  const {
    title,
    description,
    ogImage,
    siteName,
    twitterHandle,
    siteUrl = ''
  } = seo;

  const saneImage = (ogImage && saneImageSrc(ogImage)) || {};
  const canonical = url.resolve(siteUrl, currentPath);
  const type = canonical.includes('/blog/') ? 'article' : 'website';

  // TODO: pull data from blog post object
  // const { published_at, updated_at } = article || {};
  const pageTitle = currentPath?.includes('docs') ? title + ` | Clerk` : title;
  const canonicalDocs = `https://clerk.com` + canonical;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <link
        rel="canonical"
        href={currentPath?.includes('docs') ? canonicalDocs : canonical}
      />

      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={canonical} />

      <meta
        property="og:image"
        content={`${saneImage.src}&w=1200&h=630&fm=png`}
      />
      <meta property="og:image:width" content={`${saneImage.width}`} />
      <meta property="og:image:height" content={`${saneImage.height}`} />

      <meta property="og:type" content={type} />
      {/* {published_at && (
        <meta property='article:published_time' content={published_at} />
      )}
      {updated_at && (
        <meta property='article:modified_time' content={updated_at} />
      )} */}

      <meta property="twitter:title" content={pageTitle} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:url" content={canonical} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content={twitterHandle} />
      <meta
        name="twitter:image"
        content={`${saneImage.src}&w=1200&h=670&fm=png`}
      />
    </Head>
  );
}

export default SEO;
